<template>
  <div id="Home">
    <section class="section section-top">
      <!-- Cover -->
      <div class="bg-cover" style="background-color: #ffffff;"></div>

      <!-- Overlay -->
      <!-- <div class="bg-overlay"></div> -->

      <!-- Triangles -->
      <div
        class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-left"
      ></div>
      <div
        class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-right"
      ></div>

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-8 col-lg-7">
            <!-- Preheading -->
            <p
              class="font-weight-medium text-center text-xl text-uppercase text-black"
            >
              <img src="assets/img/logo_ezcli_big.svg" width="256px" />
            </p>

            <!-- Subheading -->
            <p class="lead text-black text-center mb-5">
              at ezcli.com we focus on delivering end to end online market
              research solutions
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
    <section class="section bg-light">
      <!-- Line -->
      <div class="line line-top"></div>

      <!-- Triangles -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h2 class="text-center mb-4">
              Services
            </h2>

            <!-- Subheading -->
            <p class="text-muted text-center mb-5"></p>
          </div>
        </div>
        <!-- / .row -->

        <div class="row">
          <div class="col-md-4">
            <!-- Item -->
            <router-link :to="`/apr`" replace>
              <!-- Image -->
              <div class="card-img">
                <img
                  src="assets/img/logo_big_apr.svg"
                  alt="App Reviews"
                  class="img-fluid"
                />
              </div>
            </router-link>
            <!-- / .card -->
          </div>
          <div class="col-md-4">
            <!-- Item -->
            <router-link :to="`/mr`" replace>
              <!-- Image -->
              <div class="card-img">
                <img
                  src="assets/img/logo_big_mr.svg"
                  alt="Online Market Research"
                  class="img-fluid"
                /></div
            ></router-link>
            <!-- / .card -->
          </div>
          <div class="col-md-4">
            <!-- Item -->
            <!-- Image -->
            <div class="card-img--inactive">
              <img
                src="assets/img/logo_big_sa.svg"
                alt="Sentiment Analysis"
                class="img-fluid"
              />
            </div>
            <!-- / .card -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss">
/* /<style lang="scss">
.card-img {
    &--dimmed{
      opacity: 33%;
    }
} */
.card-img {
  &--inactive {
    opacity: 12.5%;
    filter: grayscale(100%);
  }
}
</style>
